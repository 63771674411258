import React, { useState } from "react";
import { Avatar, Tabs, Row, Card, Button, Form, Icon, Tooltip, Divider, Table, Modal } from 'antd';
import InputMask from "react-input-mask";
import profilePic from "../../assets/template/images/client/slack-1.svg";
import mensagem from "../../components/messages/message";
import './styles.css';
import Title from "antd/lib/typography/Title";
import TelefoneInput from "../../components/masks/telefone";
import API from '../../components/api/api';

const api = new API();


const { Meta } = Card;
const { TabPane } = Tabs;

var mapStyle = {
    height: "150px",
    width: "100%",
    backgroundColor: "#8B3694"
};
var cardStyle = {
    width: "100%",
    marginBottom: "20px"
};

var avatarStyle = {
    marginTop: "-50px",
    marginBottom: "20px",
    width: "100px",
    height: "100px"
};

function callback(key) {
    console.log(key);
}

const LoginForm = (props) => {
    const { getFieldDecorator } = props.form;
    const { onSubmit } = props;

    const handleSubmit = (e) => {
        e.preventDefault();

        props.form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    };

    return (
        <Form onSubmit={handleSubmit} className="login-form">
            <Form.Item>
                {getFieldDecorator('taxId', {
                    rules: [{ required: true, message: 'Digite seu CPF!' }]
                })(
                    <InputMask
                        type="tel"
                        placeholder="CPF"
                        mask="999.999.999-99"
                        className="inputText cpf"
                    />
                )}
            </Form.Item>

            <Form.Item>
                <Button type="primary" className="login-form-button" style={{ width: '100%' }}>
                    Verificar CPF
                </Button>
            </Form.Item>
        </Form>
    );
}

const PainelCliente = () => {
    const [userAuthenticated, setUserAuthenticated] = useState(false);
    const [instrucaoUsuario, setInstrucaoUsuario] = useState('Informe seu CPF para acessar o programa de fidelidade');
    const [authStep, setAuthStep] = useState(0);
    const [cpf, setCPF] = useState('');
    const [emailBeneficiario, setEmailBeneficiario] = useState('');
    const [telefone, setTelefone] = useState('');
    const [maskedPhone, setMaskedPhone] = useState('*********');
    const [colunasPremios, setColunasPremios] = useState([]);
    const [premiosData, setPremiosData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [beneficiario, setBeneficiario] = useState('');
    const [pontos, setPontos] = useState([]);

    const [modalVisible, setModalVisible] = useState(false);
    const [modalImage, setModalImage] = useState('');

    const [token, setToken] = useState('');

    const findByCPF = async () => {
        setLoading(true);

        if (!cpf || cpf.trim() === '') {
            mensagem.openNotificationWithIcon('error', 'CPF Inválido', 'O CPF informado não é válido. Verifique e tente novamente.');
            setLoading(false);
            return;
        }

        const obj = await api.getPersonByCPF(cpf, `Bearer ${cpf}`);
        const beneficiario = obj.beneficiario;

        if (!beneficiario) {
            mensagem.openNotificationWithIcon('error', 'Beneficiário Não Encontrado', 'Verifique se o CPF está correto.');
            setLoading(false);
            return;
        }


        setBeneficiario(beneficiario);

        // considerarei sempre o primeiro beneficiário com telefone
        const phoneNumber = beneficiario.endereco.telefone;
        const formatedPhoneNumber = formatPhoneNumber(phoneNumber);
        const mskPhn = maskPhoneNumber(formatedPhoneNumber);
        setMaskedPhone(mskPhn);        
        setInstrucaoUsuario(`Confirme seu telefone com final ${mskPhn}`);
        setAuthStep(1);
        montarColunasPremios();
        setLoading(false);
    }

    const formatPhoneNumber = (phone) => {
        const digits = phone.replace(/\D/g, '');
        if (digits.length === 11) { 
            return `(${digits.slice(0, 2)}) ${digits.slice(2, 7)}-${digits.slice(7)}`;
        } else if (digits.length === 10) {
            return `(${digits.slice(0, 2)}) ${digits.slice(2, 6)}-${digits.slice(6)}`;
        }
        return phone;
    };

    const maskPhoneNumber = (phone) => {
        const digits = phone.replace(/\D/g, '');
        if (digits.length > 4) {
            return `${"*".repeat(digits.length - 4)}${digits.slice(-4)}`;
        }
        return "****";
    };

    const maskEmail = (email) => {
        const [username, domain] = email.split('@');
        if (username.length <= 2) {
            return `${"*".repeat(username.length)}@${domain}`;
        } else {
            const visiblePart = username.slice(0, 2);
            const maskedPart = "*".repeat(username.length - 2);
            return `${visiblePart}${maskedPart}@${domain}`;
        }
    };    

    const cofirmPhoneNumber = async () => {
        setLoading(true);

        const phoneNumber = beneficiario.endereco.telefone;
        const formatedPhoneNumber = formatPhoneNumber(phoneNumber);
        if (telefone !== formatedPhoneNumber) {
            mensagem.openNotificationWithIcon('error', 'Telefone Incorreto', 'Verifique se o telefone está correto.');
            setLoading(false);
            return;
        }

        // pego o email do usuário. Se não tiver, peço para ele informar. Se houver, mostro mascarado
        const email = beneficiario.email;
        if (!email || email.trim() === '') {
            setInstrucaoUsuario('Cadastre seu email abaixo');
        } else {
            const maskedEmail = maskEmail(email);
            setInstrucaoUsuario(`Confirme seu email: ${maskedEmail}`);
        }

        setAuthStep(2);
        setLoading(false);
    }

    const sendEmailToken = async () => {
        setLoading(true);

        const email = beneficiario.email;
        if (email && email !== emailBeneficiario) {
            mensagem.openNotificationWithIcon('error', 'Email Incorreto', 'O email informado não está de acordo com o que foi cadastrado.');
            setLoading(false);
            return;
        }

        // valido se o email é válido
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailBeneficiario || !emailBeneficiario.trim() || !emailRegex.test(emailBeneficiario)) {
            mensagem.openNotificationWithIcon('error', 'Email Inválido', 'O email informado não é válido. Verifique e tente novamente.');
            setLoading(false);
            return;
        }

        await api.sendEmailToken(emailBeneficiario);

        // atualizo o email do beneficiário
        if (!email) {
            const key = beneficiario.key;
            const data = beneficiario;
            beneficiario.email = emailBeneficiario;
            //await beneficiarioDAO.update(key, data);
        }

        setBeneficiario(beneficiario);

        setInstrucaoUsuario(`Enviamos um token para o seu email. Por favor, informe-o abaixo.`);
        setAuthStep(3);
        setLoading(false);
    }

    const validateEmailToken = async () => {
        api.validateToken(emailBeneficiario, token).then((response) => {
            getRegistros();
            setUserAuthenticated(true);            
        }
        ).catch((error) => {
            mensagem.openNotificationWithIcon('error', 'Token Inválido', 'O token informado não é válido. Verifique e tente novamente.');
        });
    };

    const montarColunasPremios = () => {
        const colunas = [
            {
                title: 'Imagem',
                dataIndex: 'imageUrl',
                key: 'imageUrl',
                render: (text, record) => (
                    <img 
                        src={record.imageUrl} 
                        alt={record.premio} 
                        style={{ width: '50px', height: 'auto' }} 
                    />
                )
            },
            {
                title: 'Ver',
                key: 'btnVerImagem',
                render: (text, record) => (
                    <Button onClick={() => showModal(record.imageUrl)}>
                        Ver Prêmio
                    </Button>
                )
            },

            {
                title: 'Pontos',
                dataIndex: 'pontos',
                key: 'pontos',
            },
            {
                title: 'Prêmio',
                dataIndex: 'premio',
                key: 'premio',
            },
            {
                title: 'Cód Produto *',
                dataIndex: 'codProduto',
                key: 'codProduto',
            }            
        ];
        setColunasPremios(colunas);
    };
    
    const showModal = (imageUrl) => {
        setModalImage(imageUrl);
        setModalVisible(true);
    };
    
    const closeModal = () => {
        setModalVisible(false);
        setModalImage('');
    };    

    // para baixo é código original



    const getPontos = async (beneficiarioId) => {
        const registroPonto = await api.getPointsCustom(beneficiarioId, `Bearer ${cpf}`);
        mountPremios(registroPonto[0].convenioId);
        return registroPonto;
    }

    const mountPremios = async (convenioId) => {

        const convenio = await api.getConvenioById(convenioId, `Bearer ${cpf}`);
        debugger;
        const premios = convenio.administrador?.premiacao?.fidelidade?.premios ? convenio.administrador.premiacao.fidelidade.premios : [];

        const premiosData = premios.map(premio => {
            return {
                key: premio.codProduto,
                codProduto: premio.codProduto,
                pontos: premio.pontos,  
                premio: premio.premio,
                imageUrl: premio.imageUrl
            }
        });

        setPremiosData(premiosData);
    }

    const getRegistros = async () => {
        const pontosPromise = [];

        if (!beneficiario) {
            mensagem.openNotificationWithIcon('error', 'Beneficiário Não Encontrado', 'Verifique se o CPF e o telefone estão corretos.');
            setUserAuthenticated(false);
            return;
        }

        const registroPonto = await getPontos(beneficiario.key);
        pontosPromise.push(registroPonto);

        const registrosPontos = await Promise.all(pontosPromise);
        setPontos(...registrosPontos);
    };

    return (
        <div>
            <Row type="flex" justify="center" align="top">
                <div style={mapStyle}></div>
            </Row>
            <Row type="flex" justify="center" align="top">
                <Avatar style={avatarStyle} src={profilePic} />
            </Row>

            {!userAuthenticated && (
                <div>
                    <Row type="flex" justify="center" align="top">
                        <Title level={2}>Boas vindas ao MOUB Fidelidade</Title>
                    </Row>
                    <Row type="flex" justify="center" align="top">
                        <Title level={4}>{instrucaoUsuario}</Title>
                    </Row>

                    {authStep === 0 && (
                        <Row type="flex" justify="center">
                            <InputMask
                                type="tel"
                                placeholder="CPF"
                                mask="999.999.999-99"
                                className="inputText cpf"
                                onChange={event => setCPF(event.target.value)}
                                disabled={loading}
                                value={cpf}
                            />
                            <Button type="primary" className="cliente-fidelidade-cpf" onClick={findByCPF} loading={loading}>
                                Verificar
                            </Button>
                        </Row>
                    )}

                    {authStep === 1 && (
                        <Row type="flex" justify="center">
                            <TelefoneInput
                                className="inputPoint phone-number-client"
                                value={telefone}
                                onChange={event => setTelefone(event.target.value)}
                                disabled={false}>                                    
                            </TelefoneInput>
                            <Button type="primary" className="cliente-fidelidade-phone" onClick={cofirmPhoneNumber} loading={loading}>
                                Verificar
                            </Button>
                        </Row>
                    )}

                    {authStep === 2 && (
                        <Row type="flex" justify="center">
                            <InputMask
                                type="text"
                                placeholder="e-mail"
                                mask=""
                                className="inputText email-client"
                                onChange={event => setEmailBeneficiario(event.target.value)}
                                disabled={loading}
                                value={emailBeneficiario}
                            />
                            <Button type="primary" className="cliente-fidelidade-email" onClick={sendEmailToken} loading={loading}>
                                Enviar Token Por Email
                            </Button>
                        </Row>
                    )}

                    {authStep === 3 && (
                        // crio um input para validar o token enviado para o e-mail do usuário
                        <Row type="flex" justify="center">
                            <InputMask
                                type="text"
                                placeholder="Token"
                                mask="999999"
                                className="inputText token-client"
                                onChange={event => setToken(event.target.value)}
                                disabled={loading}
                            />
                            <Button type="primary" className="cliente-fidelidade-token" onClick={validateEmailToken} loading={loading} >
                                Validar Token
                            </Button>
                        </Row>
                    )}
                </div>
            )}

            {userAuthenticated && (
                <div>
                    <Row type="flex" justify="center">
                        <h1>{`${beneficiario.nome ?? ''} ${beneficiario.sobrenome ?? ''}`}</h1>
                    </Row>

                    <Row type="flex" justify="center" align="top">
                        <Tabs
                            size="small"
                            defaultActiveKey="1"
                            onChange={callback}
                        >
                            <TabPane tab="Programa de Pontos" key="1">
                                {pontos.map(ponto => (
                                    <Card
                                        key={ponto.key}
                                        style={cardStyle}
                                    >
                                        <Meta
                                            avatar={<Icon type="trophy" />}
                                            title={ponto.convenio}
                                            description={`${ponto.saldo} pontos`}
                                        />
                                    </Card>
                                ))}
                            </TabPane>
                            {/* <TabPane tab="Histórico de Pontos" key="2">
                                <p>Content of Tab Pane 2</p>
                                <p>Content of Tab Pane 2</p>
                                <p>Content of Tab Pane 2</p>
                            </TabPane>
                            <TabPane tab="Resgate de Prêmios" key="3">
                                <p>Content of Tab Pane 3</p>
                                <p>Content of Tab Pane 3</p>
                                <p>Content of Tab Pane 3</p>
                            </TabPane> */}
                            <TabPane tab="Prêmios" key="4">
                                <Table pagination={{ pageSize: 10, position: 'top' }} 
                                    columns={colunasPremios}
                                    dataSource={premiosData}
                                    bordered
                                    title={() => 'Prêmios disponíveis'}
                                    footer={() => 'Fim da lista'}
                                />

                                <Modal 
                                    visible={modalVisible} 
                                    onCancel={closeModal} 
                                    footer={null} 
                                    centered
                                    width={600}
                                >
                                    <img 
                                        src={modalImage} 
                                        alt="Imagem do Prêmio" 
                                        style={{ width: '100%', height: 'auto' }} 
                                    />
                                </Modal>

                            </TabPane>
                        </Tabs>
                    </Row>
                </div>
            )}
        </div>
    );
    
}


export default PainelCliente;